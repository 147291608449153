<template>
  <el-form :model="form" :rules="rules" ref="form" label-width="150px" size="mini">
    <el-row>
      <el-col :span="12">
        <el-form-item label="调拨单编号" prop="code">
          <el-input v-model="code" readonly></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="由(A)库房" prop="cangku">
          <el-select v-model="form.yikukufang" placeholder="请选择所属仓库" @change="setDiaobodanCode">
            <el-option v-for="item in cangkus_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="到(B)库房" prop="cangku">
          <el-select v-model="form.jieshoukufang" placeholder="请选择所属仓库">
            <el-option v-for="item in cangkus_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row> </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="材料名称" prop="cailiao">
          <el-select v-model="form.cailiao" placeholder="请选择材料名称">
            <el-option v-for="item in cailiaos_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="材料规格" prop="cailiaoguige">
          <el-select v-model="form.cailiaoguige" placeholder="请选择材料规格">
            <el-option v-for="item in cailiaoguiges_options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12">
        <el-form-item label="日期" prop="date">
          <el-date-picker type="date" :picker-options="pickerOptions" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="form.date" style="width: 100%;">
          </el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="数量" prop="yikushu">
          <el-input v-model.number="form.yikushu"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item label="备注" prop="remark">
      <el-input type="textarea" rows="3" v-model="form.remark"></el-input>
    </el-form-item>
    <el-form-item>
      <div style="float:right;">
        <el-button @click="resetForm('form')"> 取消 </el-button>
        <el-button type="primary" @click="submitForm('form')"> 确认添加 </el-button>
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import utils from '@/assets/js/utils'
export default {
  props: {},
  data() {
    return {
      form: {},
      code: '',
      XiangmuGongdiOptions: [],
      loading: false,
      isValidationOk: false,
      cangkus_options: [],
      cailiaos_options: [],
      cailiaoguiges_options: [],
      cailiaotongzhidans_options: [],
      currentUserCompany: utils.getUserCookie().company.code,

      pickerOptions: {
        // 限制只能选择今天之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      rules: {
        cailiaotongzhidan: [{ required: true, message: '请输入材料通知单编号', trigger: 'blur' }],
        yikukufang: [{ required: true, message: '请选择所属仓库', trigger: 'blur' }],
        jieshoukufang: [{ required: true, message: '请选择材料名称', trigger: 'blur' }],
        cailiaoguige: [{ required: true, message: '请选择材料规格', trigger: 'blur' }],
        yikushu: [{ required: true, message: '请输入数量', trigger: 'blur' }, { type: 'number', message: '数量必须为数字' }],
        date: [{ required: true, message: '请选择调配时间', trigger: 'blur' }]
      }
    }
  },
  methods: {
    getCangkus() {
      this.$axios.get('/getCangkus/').then(res => {
        console.log('getCangkus list:', res.data)
        res.data.forEach(element => {
          let cangku = {}
          cangku.label = String(element.name)
          cangku.value = element.id
          this.cangkus_options.push(cangku)
        })
        console.log('cangkus_options', this.cangkus_options)
      })
    },
    getCailiaos() {
      this.$axios.get('/getCailiaos/').then(res => {
        console.log('getCailiaos list:', res.data)
        res.data.forEach(element => {
          let cailiao = {}
          cailiao.label = String(element.name)
          cailiao.value = element.id
          this.cailiaos_options.push(cailiao)
        })
        console.log('cailiaos_options', this.cailiaos_options)
      })
    },
    getCailiaoguiges() {
      this.$axios.get('/getCailiaoguiges/').then(res => {
        console.log('getCailiaoguiges list:', res.data)
        res.data.forEach(element => {
          let cailiaoguige = {}
          cailiaoguige.label = String(element.name)
          cailiaoguige.value = element.id
          this.cailiaoguiges_options.push(cailiaoguige)
        })
        console.log('cailiaoguiges_options', this.cailiaoguiges_options)
      })
    },
    //提交表单
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          console.log('validation ok!')
          this.form.code = this.code
          this.$emit('submitForm', this.form)
          this.form = {}
        } else {
          console.log('Validation error!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.form = {}
      this.$emit('resetForm')
    },
    setDiaobodanCode() {
      this.$axios
        .get('/getYikudiaobodanLastID/', {
          params: {
            currentUserKufang: this.form.yikukufang //当前用户所属库房id
          }
        })
        .then(res => {
          console.log('getYikudiaobodanLastID:', res.data)
          this.code = res.data
        })
    }
  },
  created() {
    this.getCangkus(), this.getCailiaos(), this.getCailiaoguiges()
  }
}
</script>

<style lang="scss" scoped></style>
